div.maplibregl-popup-content {
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
}

.maplibregl-popup-tip {
  display: none;
}
